import { firebaseDb } from '../firebase/config'
import { collection, addDoc, updateDoc, deleteDoc, query, where, limit, doc, getDoc, getDocs } from 'firebase/firestore'

class user {
    constructor(id, uid, email, firstName, lastName) {
        this.id = id;
        this.uid = uid;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    toString() {
        return this.firstName + ' ' + this.lastName;
    }
}

const userConverter = {
    toFirestore: (user) => {
        return {
            uid: user.uid,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new user(snapshot.id, data.uid, data.email, data.firstName, data.lastName);
    },
};

async function getUser(uid) {
    const userCollection = collection(firebaseDb, 'user');

    const userQuery = query(userCollection, where('uid', '==', uid), limit(1)).withConverter(userConverter);
    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.docs.length > 0) {
        return querySnapshot.docs[0].data();
    }
    else {
        return null;
    }
};

async function updateUser(uid, firstName, lastName) {
    const userCollection = collection(firebaseDb, 'user');

    const userQuery = query(userCollection, where('uid', '==', uid), limit(1)).withConverter(userConverter);
    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.docs.length > 0) {
        const existingUser = querySnapshot.docs[0];
        await updateDoc(existingUser.ref, {
            firstName: firstName,
            lastName: lastName
        });
    }
};

async function createUser(uid, firstName, lastName, email) {
    const userCollection = collection(firebaseDb, 'user');

    const existingUser = await getUser(uid);
    if (!existingUser){
        var newUser = {
            uid: uid, 
            firstName: email, 
            lastName: lastName, 
            email: email
        }
        const userRef = await (await addDoc(userCollection, newUser)).withConverter(userConverter)
    
        return userRef;
    }
    else
    {
        return existingUser;
    }
};

export {
    getUser,
    updateUser,
    createUser
};
