<template>
    <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-5 relative">
            <v-app-bar
                :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
                :dark="$vuetify.theme.dark"
                class="pt-1"
                fixed
                flat
            >
                <div class="d-flex justify-space-between align-center w-full">
                    <h6 class="ma-0">My Account</h6>
                    <slot name="userDrawerCloseButton"></slot>
                </div>
            </v-app-bar>
        </div>

        <div class="pa-5 mt-10">
            <div class="heading-label pa-0 mb-4">Profile</div>
            <div class="d-flex align-center mb-10">
                <div>
                    <v-text-field v-model="user.email" disabled label="Email Address" />
                    <v-text-field v-model="user.firstName" label="First Name" :disabled="!userEdit" />
                    <v-text-field v-model="user.lastName" label="Last Name" :disabled="!userEdit"/>
                </div>
            </div>
            <div class="pa-0 mb-4">
                    <v-btn class="primary" v-if="!userEdit" @click="userEditClick">edit</v-btn>
                    <v-btn class="secondary" v-if="userEdit" @click="userCancelClick">cancel</v-btn>&nbsp;
                    <v-btn class="primary" v-if="userEdit" @click="userSaveClick">save</v-btn>
                </div>
        </div>
    </vue-perfect-scrollbar>
</template>

<script>
import { getSessionObject, setSessionObject } from "../../session/sessionData";
import { updateUser } from "../../data/userData";

export default {
    name: 'UserDrawer',
    props: {},
    beforeMount: function() {
        const user = getSessionObject('user');
        this.user = user;
    },
    data() {
        return {
            user: null,
            userEdit: false,
        }
    },
    computed: {},
    methods: {
        async userEditClick() {
            this.userEdit = true;
        },
        async userSaveClick() {
            await updateUser(this.user.uid, this.user.firstName, this.user.lastName);
            setSessionObject('user', this.user);
            this.userEdit = false;
        }, 
        async userCancelClick() {
            this.userEdit = false;
        }
    }
}
</script>

<style scoped></style>
